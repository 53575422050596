import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
// eslint-disable-next-line import/named
import { AuthenticationResult } from "@azure/msal-browser";
import { protectedResources } from "../../../auth/authConfig";
import Spinner from "../../../components/spinner";
import { IMFAState } from "../../../types/shared";
import * as S from "./Callback.styles";

const Callback: React.FC = () => {
    const isAuthenticated = useIsAuthenticated();
    const { instance } = useMsal();
    const navigate = useNavigate();

    useEffect(() => {
        let timeout: NodeJS.Timeout;

        instance.handleRedirectPromise().then((result: AuthenticationResult | null) => {
            if (isAuthenticated) {
                if (result?.state) {
                    const state = JSON.parse(result?.state) as IMFAState;
                    console.log("We're authenticated.  Navigating to /account-setup-complete");
                    navigate(`/account-setup-complete?id=${state.Id}&p=${state.ProjectId}&t=${state.AccessToken}`);
                } else {
                    console.log("We're authenticated.  Navigating to /project");
                    navigate("/project");
                }             
            } else {
                timeout = setTimeout(() => {
                    if (isAuthenticated) {
                        console.log("Stuck on callback spinner for too long. Navigate to dashboard.");
                        navigate("/project");
                    } else {
                        console.log("Stuck on callback spinner for too long. Kick loginRedirect.");
                        instance.loginRedirect(protectedResources.graphMe).catch((e) => {
                            console.log(e);
                        });
                    }
                }, 2500);
            }
        }).catch(() => {
            console.log("Error occurred in handleRedirectPromise. Kick loginRedirect.");            
            instance.loginRedirect(protectedResources.graphMe).catch((e) => {
                console.log(e);
            });
        });

        return () => {
            clearTimeout(timeout);
        };
    }, [isAuthenticated]);

    return (
        <S.LoadingContainer>
            <Spinner size={3} padding={"0px"} />
            <h1>Please wait...</h1>
        </S.LoadingContainer>
    );
};

export default Callback;
