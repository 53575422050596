import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Text, Button, TextField, Tick, FormControl, FormErrorText } from "@breakingwave/react-ui-components";
import { useProjectAdminAssignmentStepsContext } from "../_state/context";
import { ProjectAdminAssignmentWizardStepsActionTypesEnum } from "../_state/actions";
import { ProjectAdminAssignmentWizardStepIdentifierEnum } from "../_state/types";
import { 
    projectAdminNameStepSchema, 
    ProjectAdminNameStepInputs, 
    IProjectAdminNameStepContext } from "../index.schema";
import useUserRoles from "../../../../../../../hooks/useUserRoles";
import { useProjectPageContext } from "../../../../_state/context";
import useAadAccessToken from "../../../../../../../hooks/useAadAccessToken";
import { protectedResources } from "../../../../../../../auth/authConfig";
import { MemberTypeEnum } from "../../../../../../../types/shared";
import { projectAdminBlockerReason } from "../../../../../../../utils/projectAdminAssignmentBlockerReason";
import validateProjectAdminAssignment from "../../../../../../../api/validateProjectAdminAssignment";
import * as S from "../../wizard.styles";

const ProjectAdminName: React.FC = () => {
    const { userRole } = useUserRoles();
    const { getAccessToken, isAuthenticated } = useAadAccessToken(protectedResources.wavecore.scopes);    
    const { state, dispatch } = useProjectAdminAssignmentStepsContext();
    const [validatingFormWithApi, setValidatingFormWithApi] = useState(false);

    const projectAdminNameStep = state[ProjectAdminAssignmentWizardStepIdentifierEnum.ProjectAdminName];

    const {
        state: {
            selectedProject: {
                ProjectSlug
            }
        }
    } = useProjectPageContext();

    const {
        register,
        handleSubmit,
        formState: { errors },
        setError,
        setValue,
        clearErrors
    } = useForm<ProjectAdminNameStepInputs, IProjectAdminNameStepContext>({
        resolver: yupResolver(projectAdminNameStepSchema),
        context: {
            userRole
        }
    });

    const processAndGoToNext = (data: ProjectAdminNameStepInputs): void => {
        const { firstName, lastName, contactEmailAddress } = data;

        setValidatingFormWithApi(true);

        getAccessToken().then(async (accessToken: string) => {
            if (accessToken && isAuthenticated) {
                const result = await validateProjectAdminAssignment({
                    ContactEmailAddress: contactEmailAddress,
                    FirstName: firstName,
                    LastName: lastName,
                    MemberType: MemberTypeEnum.DB,
                    UserPrincipalName: undefined
                }, ProjectSlug, accessToken);

                let validAtApiLevel = true;

                const bitwise = result.ValidationResult;
                if (bitwise > 0) {
                    setError("firstName", { message: projectAdminBlockerReason(bitwise) });
                    validAtApiLevel = false;
                }

                setValidatingFormWithApi(false);

                if (validAtApiLevel) {
                    dispatch({
                        type: ProjectAdminAssignmentWizardStepsActionTypesEnum.SET_PROJECT_ADMIN_NAME,
                        payload: {
                            ...projectAdminNameStep,
                            answer: {
                                firstName,
                                lastName,
                                contactEmailAddress,
                                displayValue: `${firstName} ${lastName}`
                            }
                        }
                    });               
            
                    dispatch({
                        type: ProjectAdminAssignmentWizardStepsActionTypesEnum.LOAD_NEXT_STEP
                    });
                }
            }
        });
    };

    return (
        <>
            <S.QuestionWrapper>
                <Text size={14} weight={500}>
                    Name
                </Text>
            </S.QuestionWrapper>
            <S.AnswerWrapper>
                <S.TeamMemberNameAndEmailContainer>
                    <FormControl as="fieldset">
                        <TextField
                            {...register("firstName")}
                            defaultValue={projectAdminNameStep.answer.firstName}
                            placeholder="First name"
                            error={!!errors.firstName}
                            onChange={(e) => {
                                setValue("firstName", e.target.value);
                                clearErrors("firstName");
                                clearErrors("lastName");                                
                            }}
                        />

                        {errors.firstName && <FormErrorText>{errors.firstName.message}</FormErrorText>}
                    </FormControl>
                    <FormControl as="fieldset">
                        <TextField
                            {...register("lastName")}
                            defaultValue={projectAdminNameStep.answer.lastName}
                            placeholder="Last name"
                            error={!!errors.lastName}
                            onChange={(e) => {
                                setValue("lastName", e.target.value);
                                clearErrors("firstName");
                                clearErrors("lastName");
                            }}
                        />
                        {errors.lastName && <FormErrorText>{errors.lastName.message}</FormErrorText>}
                    </FormControl>
                    <FormControl as="fieldset" style={{ width: "150%"}}>
                        <TextField
                            {...register("contactEmailAddress")}
                            defaultValue={projectAdminNameStep.answer.contactEmailAddress}
                            placeholder="Email address"
                            error={!!errors.contactEmailAddress}
                            onChange={(e) => {
                                setValue("contactEmailAddress", e.target.value); 
                                clearErrors("contactEmailAddress");   
                            }}
                        />
                        {errors.contactEmailAddress && <FormErrorText>{errors.contactEmailAddress.message}</FormErrorText>}
                    </FormControl>
                </S.TeamMemberNameAndEmailContainer>
            </S.AnswerWrapper>
            <S.OptionsWrapper>
                <Button
                    isLoading={validatingFormWithApi}
                    iconSvg={<Tick />}
                    iconSize={9}
                    onClick={handleSubmit(processAndGoToNext)}
                >
                    Next
                </Button>
            </S.OptionsWrapper>
        </>
    );
};

export default ProjectAdminName;
