import React from "react";
import { Text } from "@breakingwave/react-ui-components";
import * as S from "./index.styles";

const GenericRequestHeaders: React.FC = () => <S.TabHeaderRequests>
    <Text variant="span" size={14} weight={500} color="white.75">
        Request type
    </Text>
    <Text variant="span" size={14} weight={500} color="white.75">
        Request Id
    </Text>
    <Text variant="span" size={14} weight={500} color="white.75">
        Applicant        
    </Text>
    <Text variant="span" size={14} weight={500} color="white.75">
        Date requested
    </Text>
    <Text variant="span" size={14} weight={500} color="white.75">
        Status
    </Text>    
</S.TabHeaderRequests>;

export default GenericRequestHeaders;
