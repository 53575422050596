import React, { useEffect, useState } from "react";
import { Button, FormErrorText, Text } from "@breakingwave/react-ui-components";
import useCreateGenericRequest from "../../../../api/createGenericRequest";
import useGetGenericRequestDisplayItems from "../../../../api/getGenericRequestDisplayItems";
import { GenericRequestTargetsEnum, IGenericRequestErrorResponse } from "../../../../types/genericRequests";
import { GenericRequestPageActionTypesEnum, useRequestPageContext } from "../_state/context";
import { genericRequestDomainValidationFailureReason } from "../../../../utils/genericRequestDomainFailureReason";
import * as S from "../index.styles";

const GenericRequestWizard: React.FC = () => {

    const [error, setError] = useState<string>();

    const {
        state: {
            currentGenericRequestType
        },
        dispatch
    } = useRequestPageContext();

    const {
        refetch: reloadGenericRequestDisplayItemsForApplicant
    } = useGetGenericRequestDisplayItems(GenericRequestTargetsEnum.Applicant);

    const {
        refetch: reloadGenericRequestDisplayItemsForAssignee
    } = useGetGenericRequestDisplayItems(GenericRequestTargetsEnum.Assignee);    

    const {
        mutateAsync: createGenericRequest,
        isSuccess: createGenericRequestSuccess, 
        isLoading: createGenericRequestLoading,
        data: fetchedDataAfterCreate        
    } = useCreateGenericRequest();

    const submitCreateGenericRequest = async (): Promise<void> => {
        if (currentGenericRequestType) {
            await createGenericRequest({
                RequestType: currentGenericRequestType.RequestType
            }).catch((error) => {

                const result = error.response.data as IGenericRequestErrorResponse;
                const bitwise: number = result.ValidationResult;

                setError(genericRequestDomainValidationFailureReason(bitwise));
            });
        }
    };

    const cancelRequest = (): void => {
        dispatch({
            type: GenericRequestPageActionTypesEnum.SET_CURRENT_GENERIC_REQUEST_TYPE,
            payload: null
        });
    };

    useEffect(() => {
        if (createGenericRequestSuccess && fetchedDataAfterCreate) {
            reloadGenericRequestDisplayItemsForApplicant();
            reloadGenericRequestDisplayItemsForAssignee();

            dispatch({
                type: GenericRequestPageActionTypesEnum.SET_CURRENT_GENERIC_REQUEST_TYPE,
                payload: null
            });

            dispatch({
                type: GenericRequestPageActionTypesEnum.SET_SAVED_GENERIC_REQUEST,
                payload: fetchedDataAfterCreate
            });
        }
    }, [createGenericRequestSuccess, fetchedDataAfterCreate]);

    return (
        <S.GenericRequestWrapper>
            <Text size={14} weight={500} style={{ marginBottom: 12 }}>
                {currentGenericRequestType?.DisplayName}
            </Text>

            <Text size={14} weight={300} style={{ marginBottom: 12 }}>
                {currentGenericRequestType?.DisplayMessage}
            </Text>            

            <Text size={14} weight={300} style={{ marginBottom: 12 }}>
                Once submitted you will be sent a request from via email <br />and instructions as to what to do next
            </Text>

            {error && (
                <FormErrorText style={{ marginTop: 4 }}>{error}</FormErrorText>
            )}            

            <S.OptionsWrapper>
                <Button 
                    variant="secondary" 
                    disabled={createGenericRequestLoading} 
                    onClick={cancelRequest}
                >
                    Cancel
                </Button>
                <Button
                    isLoading={createGenericRequestLoading}
                    loadingIconSize={11}
                    onClick={submitCreateGenericRequest}
                    disabled={createGenericRequestLoading}
                >
                    Send me the request form
                </Button>
            </S.OptionsWrapper>

        </S.GenericRequestWrapper>
    );
};

export default GenericRequestWizard;
