import React, { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Text, Button, TextField, Tick, FormControl, FormErrorText } from "@breakingwave/react-ui-components";
import { useTeamAssignmentStepsContext } from "../_state/context";
import { TeamAssignmentWizardStepsActionTypesEnum } from "../_state/actions";
import { TeamAssignmentWizardStepIdentifierEnum } from "../_state/types";
import { teamMemberNameStepSchema, TeamMemberNameStepInputs, ITeamMemberNameStepContext } from "../index.schema";
import useUserRoles from "../../../../../../../hooks/useUserRoles";
import useAadAccessToken from "../../../../../../../hooks/useAadAccessToken";
import { protectedResources } from "../../../../../../../auth/authConfig";
import { useProjectPageContext } from "../../../../_state/context";
import { projectTeamAssignmentBlockerReason } from "../../../../../../../utils/projectTeamAssignmentBlockerReason";
import { CreateProjectTeamAssignmentDomainValidationFailureReasonEnum } from "../../../../../../../types/teamAssignment";
import { IHomeProject } from "../../../../../../../types/projectCatalogue";
import validateProjectTeamAssignment from "../../../../../../../api/validateProjectTeamAssignment";
import * as S from "../../wizard.styles";

const TeamMemberName: React.FC = () => {
    const { userRole } = useUserRoles();
    const { getAccessToken, isAuthenticated } = useAadAccessToken(protectedResources.wavecore.scopes);
    const { state, dispatch } = useTeamAssignmentStepsContext();
    const [validatingFormWithApi, setValidatingFormWithApi] = useState(false);

    // Fully typed, shorter spelling of the step name for easy access
    const teamMemberNameStep = state[TeamAssignmentWizardStepIdentifierEnum.TeamMemberName];
    const deviceSelectionStep = state[TeamAssignmentWizardStepIdentifierEnum.DeviceSelection];

    const {
        state: {
            selectedProject
        }
    } = useProjectPageContext();

    const homeProject = useMemo<IHomeProject>(() => selectedProject as IHomeProject, []);

    const {
        register,
        handleSubmit,
        formState: { errors },
        setError,
        setValue,
        clearErrors
    } = useForm<TeamMemberNameStepInputs, ITeamMemberNameStepContext>({
        resolver: yupResolver(teamMemberNameStepSchema),
        context: {
            userRole,
            getAccessToken,
            isAuthenticated,
            projectDomain: homeProject.ProjectDomain
        }
    });

    const processAndGoToNext = (data: TeamMemberNameStepInputs): void => {
        const { firstName, lastName, contactEmailAddress } = data;

        setValidatingFormWithApi(true);

        getAccessToken().then(async (accessToken: string) => {
            if (accessToken && isAuthenticated) {
                const result = await validateProjectTeamAssignment(
                    homeProject.ProjectSlug, 
                    {
                        ContactEmailAddress: contactEmailAddress,
                        FirstName: firstName,
                        LastName: lastName
                    }, 
                    accessToken);

                let validAtApiLevel = true;

                const bitwise = result.ValidationResult;

                if ((bitwise & CreateProjectTeamAssignmentDomainValidationFailureReasonEnum.ContactEmailAddressInUse) === 
                        CreateProjectTeamAssignmentDomainValidationFailureReasonEnum.ContactEmailAddressInUse) {
                    setError("contactEmailAddress", { message: "Email is already in use" });
                    validAtApiLevel = false;
                }

                if ((bitwise & CreateProjectTeamAssignmentDomainValidationFailureReasonEnum.UserPrincipalNameInUse) === 
                       CreateProjectTeamAssignmentDomainValidationFailureReasonEnum.UserPrincipalNameInUse) {
                    setError("firstName", { message: "User already exists" });
                    validAtApiLevel = false;
                } else {
                    if ((bitwise & CreateProjectTeamAssignmentDomainValidationFailureReasonEnum.ProjectNotFound) === CreateProjectTeamAssignmentDomainValidationFailureReasonEnum.ProjectNotFound ||
                        (bitwise & CreateProjectTeamAssignmentDomainValidationFailureReasonEnum.AuthenticatedUserDoesNotHavePermission) === CreateProjectTeamAssignmentDomainValidationFailureReasonEnum.AuthenticatedUserDoesNotHavePermission ||
                        (bitwise & CreateProjectTeamAssignmentDomainValidationFailureReasonEnum.ContactEmailDomainDoesNotMatchProjectDomain) === CreateProjectTeamAssignmentDomainValidationFailureReasonEnum.ContactEmailDomainDoesNotMatchProjectDomain ||
                        (bitwise & CreateProjectTeamAssignmentDomainValidationFailureReasonEnum.InFlightProjectAdminAssignments) === CreateProjectTeamAssignmentDomainValidationFailureReasonEnum.InFlightProjectAdminAssignments ||
                        (bitwise & CreateProjectTeamAssignmentDomainValidationFailureReasonEnum.DomainNotFound) === CreateProjectTeamAssignmentDomainValidationFailureReasonEnum.DomainNotFound ||
                        (bitwise & CreateProjectTeamAssignmentDomainValidationFailureReasonEnum.InvalidDeviceType) === CreateProjectTeamAssignmentDomainValidationFailureReasonEnum.InvalidDeviceType ||
                        (bitwise & CreateProjectTeamAssignmentDomainValidationFailureReasonEnum.ContactEmailAddressInUse) === CreateProjectTeamAssignmentDomainValidationFailureReasonEnum.ContactEmailAddressInUse ||
                        (bitwise & CreateProjectTeamAssignmentDomainValidationFailureReasonEnum.UserPrincipalNameInUse) === CreateProjectTeamAssignmentDomainValidationFailureReasonEnum.UserPrincipalNameInUse ||
                        (bitwise & CreateProjectTeamAssignmentDomainValidationFailureReasonEnum.TeamNotFound) === CreateProjectTeamAssignmentDomainValidationFailureReasonEnum.TeamNotFound) {
                        setError("firstName", { message: projectTeamAssignmentBlockerReason(bitwise) });
                        validAtApiLevel = false;
                    }
                }

                setValidatingFormWithApi(false);

                if (validAtApiLevel) {
                    dispatch({
                        type: TeamAssignmentWizardStepsActionTypesEnum.SET_TEAM_MEMBER_NAME,
                        payload: {
                            ...teamMemberNameStep,
                            answer: {
                                firstName,
                                lastName,
                                contactEmailAddress,
                                displayValue: `${firstName} ${lastName}`
                            }
                        }
                    });

                    // Clear down the device selection in case the back button was hit
                    // and a device was selected that may no longer be applicable
                    dispatch({
                        type: TeamAssignmentWizardStepsActionTypesEnum.SET_DEVICE_SELECTION,
                        payload: {
                            ...deviceSelectionStep,
                            answer: {
                                deviceType: undefined
                            }
                        }
                    });                    

                    dispatch({
                        type: TeamAssignmentWizardStepsActionTypesEnum.LOAD_NEXT_STEP
                    });
                }
            }
        });
    };

    return (
        <>
            <S.QuestionWrapper>
                <Text size={14} weight={500}>
                    Name
                </Text>
            </S.QuestionWrapper>
            <S.AnswerWrapper>
                <S.TeamMemberNameAndEmailContainer>
                    <FormControl as="fieldset">
                        <TextField
                            {...register("firstName")}
                            defaultValue={teamMemberNameStep.answer.firstName}
                            placeholder="First name"
                            error={!!errors.firstName}
                            onChange={(e) => {
                                setValue("firstName", e.target.value);
                                clearErrors("firstName");
                                clearErrors("lastName");   
                            }}
                        />

                        {errors.firstName && <FormErrorText>{errors.firstName.message}</FormErrorText>}
                    </FormControl>
                    <FormControl as="fieldset">
                        <TextField
                            {...register("lastName")}
                            defaultValue={teamMemberNameStep.answer.lastName}
                            placeholder="Last name"
                            error={!!errors.lastName}
                            onChange={(e) => {
                                setValue("lastName", e.target.value);
                                clearErrors("firstName");
                                clearErrors("lastName");
                            }}
                        />
                        {errors.lastName && <FormErrorText>{errors.lastName.message}</FormErrorText>}
                    </FormControl>
                    <FormControl as="fieldset" style={{ width: "150%"}}>
                        <TextField
                            {...register("contactEmailAddress")}
                            defaultValue={teamMemberNameStep.answer.contactEmailAddress}
                            placeholder="Email address"
                            error={!!errors.contactEmailAddress}
                            onChange={(e) => {
                                setValue("contactEmailAddress", e.target.value);  
                                clearErrors("contactEmailAddress");    
                            }}
                        />
                        {errors.contactEmailAddress && <FormErrorText>{errors.contactEmailAddress.message}</FormErrorText>}
                    </FormControl>
                </S.TeamMemberNameAndEmailContainer>
            </S.AnswerWrapper>
            <S.OptionsWrapper>
                <Button
                    isLoading={validatingFormWithApi}
                    iconSvg={<Tick />}
                    iconSize={9}
                    onClick={handleSubmit(processAndGoToNext)}
                >
                    Next
                </Button>
            </S.OptionsWrapper>
        </>
    );
};

export default TeamMemberName;
