export enum ProjectAdminAssignmentWizardStepIdentifierEnum {
    ProjectAdminName = "ProjectAdminName",
    Confirmation = "Confirmation",
}

export interface IProjectAdminAssignmentWizardStepsState {
    [ProjectAdminAssignmentWizardStepIdentifierEnum.ProjectAdminName]: {
        order: number;
        isStepAnswered: boolean;
        isStepActive: boolean;
        elevateMode: boolean;
        userPrincipalName: string | undefined;
        answer: {
            firstName: string;
            lastName: string;
            contactEmailAddress: string;
            displayValue: string;
        };
    };
    [ProjectAdminAssignmentWizardStepIdentifierEnum.Confirmation]: {
        order: number;
        isStepAnswered: boolean;
        isStepActive: boolean;
        answer: {
            confirmation: boolean;
        };
    };    
}
