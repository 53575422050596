import { JoinProjectTaskAutomationKeyEnum } from "./joinProject";
import { ProjectTeamAssignmentTaskAutomationKeyEnum } from "./onboarding";
import { ProjectAdminAssignmentTaskAutomationKeyEnum } from "./projectAdminAssignment";
import { IProject } from "./projectCatalogue";
import { IProjectTeamMemberDisplayItemsResponse } from "./teamMemberDisplayItems";

export enum TaskGroupStatusesEnum {
    Pending = "Pending",
    InProgress = "InProgress",
    Complete = "Complete",
    Failed = "Failed"
}
export interface ITaskItem {
    TaskId: string;
    Description: string;
    Status: TaskGroupStatusesEnum;
    GroupId: string;
    AccessToken: string;
}

export enum ManagedProcessResourceTypeEnum {
    Indeterminate = "Indeterminate",
    ProjectAdminAssignment = "ProjectAdminAssignment",
    ProjectTeamAssignment = "ProjectTeamAssignment",
    JoinProject = "JoinProject",
    LeaveProject = "LeaveProject",
    OffboardTeamMember = "OffboardTeamMember"
}

export enum ProjectTypeEnum {
    Home = "Home",
    Working = "Working"
}

export enum MemberTypeEnum {
    DB = "DB"
}

export enum UserTypeEnum {
    User,
    ProjectAdmin,
    UserAndProjectAdmin
}

export enum RateTypeEnum {
    Daily = "Daily",
    Weekly = "Weekly",
    Monthly = "Monthly"
}

export enum RateCurrencyEnum {
    EUR = "EUR",
    GBP = "GBP",
    USD = "USD"
}

export enum CurrencySymbolsEnum {
    EUR = "€",
    GBP = "£",
    USD = "$"
}

export enum EndDateModeEnum {
    MonthlyRolling = "MonthlyRolling",
    Specified = "Specified"
}

export enum WorkingDaysModeEnum {
    WeekDays
}

export enum DeviceTypeEnum {
    VM = "VM",
    Laptop = "Laptop",
    NoDeviceRequired = "NoDeviceRequired",
}

export enum TaskItemStatusEnum {
    Created = "Created",
    Pending = "Pending",
    InProgress = "InProgress",
    Complete = "Complete",
    Failed = "Failed"
}

export interface IManagedContext {
    Id: string | undefined;
    ProjectSlugOrProjectId: string | undefined; // TODO: this will be 'ProjectId' only
    ResourceType: ManagedProcessResourceTypeEnum | undefined;
}

export interface IMFAState {
    Id: string | undefined;
    ProjectId: string | undefined;
    AccessToken: string | undefined;
}

export interface ITaskItemStatusResponse {
    ProjectName: string;
    TaskId: string;
    TaskStatus: TaskItemStatusEnum;
    TaskDependencyId: string;
    TaskDependencyStatus: TaskItemStatusEnum;
}

export interface IProjectMembershipDto {
    Project: IProject;
    ProjectAdmins: IProjectTeamMemberDisplayItemsResponse[];
}

export interface Dictionary<T> {
    [Key: string]: T;
}

export type TaskAutomationKeyUnion = 
    | ProjectTeamAssignmentTaskAutomationKeyEnum 
    | ProjectAdminAssignmentTaskAutomationKeyEnum 
    | JoinProjectTaskAutomationKeyEnum;

