import React from "react";
import { Heading } from "@breakingwave/react-ui-components";
import useGetGenericRequestDisplayItems from "../../../api/getGenericRequestDisplayItems";
import Spinner from "../../../components/spinner";
import { GenericRequestTargetsEnum } from "../../../types/genericRequests";
import { GenericRequestStateEnum } from "../../../types/genericRequestDisplayItems";
import RequestSelect from "./GenericRequestSelect";
import { useRequestPageContext } from "./_state/context";
import GenericRequestGroupStatuses from "./generic-request-group-statuses";
import GenericRequestHeaders from "./GenericRequestHeaders";
import GenericRequestRow from "./generic-request-row";
import GenericRequestWizard from "./generic-request-wizard";
import * as S from "./index.styles";

const GenericRequests: React.FC = () => {

    const {
        state: {
            currentGenericRequestType
        }
    } = useRequestPageContext();

    const {
        data: genericRequestsForApplicant,
        isLoading: isLoadingGenericRequestsForApplicant
    } = useGetGenericRequestDisplayItems(GenericRequestTargetsEnum.Applicant);

    const {
        data: genericRequestsAssignedToUser
    } = useGetGenericRequestDisplayItems(GenericRequestTargetsEnum.Assignee);

    return <>
   
        <S.RequestsHeader>
            {isLoadingGenericRequestsForApplicant ? <Spinner /> : 
                <Heading variant="h5" weight={300}>
                    {genericRequestsForApplicant && genericRequestsForApplicant.length > 0 ? `Your requests (${genericRequestsForApplicant.length})` : "You currently have no requests"}           
                </Heading>
            }            
            <RequestSelect />
        </S.RequestsHeader>
        
        {(currentGenericRequestType || (genericRequestsForApplicant && genericRequestsForApplicant.length > 0)) && <>
            <GenericRequestHeaders />
            {currentGenericRequestType && <GenericRequestWizard />}

            {!isLoadingGenericRequestsForApplicant && 
             genericRequestsForApplicant && 
             genericRequestsForApplicant.map((genericRequest) => 
                 (genericRequest.State === GenericRequestStateEnum.Active) ? 
                     <GenericRequestGroupStatuses              
                         key={`generic_request_for_applicant_${genericRequest.MondayBoardId}`}
                         target={GenericRequestTargetsEnum.Applicant}
                         genericRequest={genericRequest} />
                     :
                     <GenericRequestRow
                         key={`generic_request_for_applicant_${genericRequest.MondayBoardId}`}
                         genericRequest={genericRequest} />                   
             )}

            {isLoadingGenericRequestsForApplicant && <Spinner />}
        </>}


        {(genericRequestsAssignedToUser && genericRequestsAssignedToUser.length > 0) && <>
            <S.RequestsHeader marginTop="4em">
                <Heading variant="h5" weight={300}>
                    Assigned to you ({genericRequestsAssignedToUser.length})
                </Heading>
            </S.RequestsHeader>
            
            <GenericRequestHeaders />

            {genericRequestsAssignedToUser.map((genericRequest) => 
                (genericRequest.State === GenericRequestStateEnum.Active) ? 
                    <GenericRequestGroupStatuses
                        key={`generic_request_for_assignee_${genericRequest.MondayBoardId}`}
                        target={GenericRequestTargetsEnum.Assignee}
                        genericRequest={genericRequest} /> :

                    <GenericRequestRow
                        key={`generic_request_for_assignee_${genericRequest.MondayBoardId}`}
                        genericRequest={genericRequest} />  
            )}
        </>}
        <S.Line />
    </>;
};

export default GenericRequests;
