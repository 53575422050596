import React from "react";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { Flip, ToastContainer } from "react-toastify";
import { BWThemeProvider } from "@breakingwave/react-ui-components";
import { reactPlugin } from "../utils/appinsights";
import ScrollToTop from "../utils/scrolltotop";
import Homepage from "../pages/public/homepage";
import Attestation from "../pages/public/onboarding/attestation/Attestation";
import Project from "../pages/private/project";
import NotFound from "../pages/public/not-found";
import ErrorPage from "../pages/public/error-page";
import { msalConfig } from "../auth/authConfig";
import { Login, Callback } from "../pages/public/auth";
import OnboardingLandingPage from "../pages/public/onboarding/onboarding-landing/OnboardingLandingPage";
import Training from "../pages/public/onboarding/training/Training";
import DeviceRequirement from "../pages/public/onboarding/request-device/DeviceRequirement";
import Thanks from "../pages/public/onboarding/thanks/Thanks";
import OnboardingApproval from "../pages/public/onboarding-approval/OnboardingApproval";
import OnboardingProjectAdminLandingPage from "../pages/public/onboarding-project-admin/onboarding-project-admin-landing/OnboardingProjectAdminLandingPage";
import OnboardingProjectAdminThanks from "../pages/public/onboarding-project-admin/onboarding-project-admin-thanks/OnboardingProjectAdminThanks";
import OnboardingProjectAdminTerms from "../pages/public/onboarding-project-admin/onboarding-project-admin-terms/OnboardingProjectAdminTerms";
import JoinApproval from "../pages/public/join-approval/JoinApproval";
import JoinApprovalModerator from "../pages/public/join-approval-moderator/JoinApprovalModerator";
import AccountSetupComplete from "../pages/private/account-setup-complete";
import AccountSetupLogin from "../pages/public/auth/AccountSetupLogin";
import ProcessTask from "../pages/public/onboarding/process-task/ProcessTask";
import OnboardingTeamOwnerApproval from "../pages/public/onboarding-team-owner-approval/OnboardingTeamOwnerApproval";
import { queryClient } from "./queryClient";
import OnboardingRoute from "./OnboardingRoute";
import PrivateRoute from "./PrivateRoute";
import OnboardingProjectAdminRoute from "./OnboardingProjectAdminRoute";
import "react-toastify/dist/ReactToastify.css";

const msalInstance = new PublicClientApplication(msalConfig);

const App: React.FC = () => {
    console.log("Render App");

    return (
        <BWThemeProvider>
            <HelmetProvider>
                <MsalProvider instance={msalInstance}>
                    <QueryClientProvider client={queryClient}>
                        <BrowserRouter basename="/">
                            <ScrollToTop />
                            <Routes>
                                <Route path="/" element={<Homepage />} />
                                <Route path="/login" element={<Login />} />
                                <Route path="/account-setup-login" element={<AccountSetupLogin />} />
                                <Route path="/account-setup-complete" element={<AccountSetupComplete />} />
                                <Route path="/callback" element={<Callback />} />                                
                                <Route path="/onboarding-approval" element={<OnboardingApproval />} />
                                <Route path="/onboarding-team-owner-approval" element={<OnboardingTeamOwnerApproval />} />
                                <Route path="/join-approval" element={<JoinApproval />} />
                                <Route path="/join-approval-moderator" element={<JoinApprovalModerator />} />
                                <Route element={<OnboardingRoute />}>
                                    <Route path="/onboarding/*" element={<OnboardingLandingPage />} />
                                    <Route path="/attestation-task/*" element={<Attestation />} />
                                    <Route path="/training-task/*" element={<Training />} />
                                    <Route path="/device-requirement/*" element={<DeviceRequirement />} />
                                    <Route path="/process-onboarding-task/*" element={<ProcessTask />} />
                                    <Route path="/onboarding-thank-you" element={<Thanks />} />
                                </Route>
                                <Route element={<OnboardingProjectAdminRoute />}>
                                    <Route path="/project-admin-onboarding/*" element={<OnboardingProjectAdminLandingPage />} />
                                    <Route path="/project-admin-terms-task/*" element={<OnboardingProjectAdminTerms />} />
                                    <Route path="/project-admin-onboarding-thank-you" element={<OnboardingProjectAdminThanks />} />
                                </Route>
                                <Route element={<PrivateRoute />}>                                    
                                    <Route path="project" element={<Project />} />
                                    <Route path="project/:projectSlug" element={<Project />}>
                                        <Route path=":tabSlug" />
                                    </Route>
                                </Route>
                                <Route path="/error" element={<ErrorPage />} />
                                <Route path="*" element={<NotFound />} />
                                {/* BACKLOG: Below pages are also available, but they are not in use */}
                                {/* <Route path="/about-us" element={<AboutUs />} /> */}
                                {/* <Route path="/our-services" element={<OurServices />} /> */}
                            </Routes>
                        </BrowserRouter>
                        <ToastContainer
                            hideProgressBar={true}
                            theme="dark"
                            transition={Flip}                            
                        />
                        <ReactQueryDevtools initialIsOpen={false} />
                    </QueryClientProvider>
                </MsalProvider>
            </HelmetProvider>
        </BWThemeProvider>);
};

export default withAITracking(reactPlugin, App);
