/* eslint-disable @typescript-eslint/no-unused-vars */
import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";
import axios from "axios";
import useAadAccessToken from "../hooks/useAadAccessToken";
import { protectedResources } from "../auth/authConfig";
import { QueryKeysEnum } from "../types/queryKeys";
import { GenericRequestTargetsEnum } from "../types/genericRequests";
import { IGenericRequestDisplayItemsResponse } from "../types/genericRequestDisplayItems";

const getGenericRequestDisplayItems = async (accessToken: string, target: GenericRequestTargetsEnum): Promise<IGenericRequestDisplayItemsResponse[]> => {
    const url = `${protectedResources.wavecore.endpoint}/api/GenericRequestDisplayItems?target=${target}`;
    const response = await axios({
        method: "get",
        url,
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`
        }
    });

    return response.data;
};

const useGetGenericRequestDisplayItems = (
    target: GenericRequestTargetsEnum,
    options?: UseQueryOptions<IGenericRequestDisplayItemsResponse[], unknown, IGenericRequestDisplayItemsResponse[], string[]>
): UseQueryResult<IGenericRequestDisplayItemsResponse[], unknown> => {
    const { getAccessToken, isAuthenticated } = useAadAccessToken(protectedResources.wavecore.scopes);

    return useQuery(
        [QueryKeysEnum.genericRequestDisplayItems, target.toString()],
        () => 
            getAccessToken().then((accessToken) => {
                if (accessToken && isAuthenticated) {
                    return getGenericRequestDisplayItems(accessToken, target);
                }
                return Promise.reject();
            }),
        {
            ...options
        }
    );    
};

export default useGetGenericRequestDisplayItems;
