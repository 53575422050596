import { CreateProjectTeamAssignmentDomainValidationFailureReasonEnum } from "../types/teamAssignment";

const projectTeamAssignmentBlockerReason = (bitwise: number): string => {
    if ((bitwise & CreateProjectTeamAssignmentDomainValidationFailureReasonEnum.ProjectNotFound) === 
            CreateProjectTeamAssignmentDomainValidationFailureReasonEnum.ProjectNotFound) {
        return "Project not found";
    }

    if ((bitwise & CreateProjectTeamAssignmentDomainValidationFailureReasonEnum.AuthenticatedUserDoesNotHavePermission) === 
            CreateProjectTeamAssignmentDomainValidationFailureReasonEnum.AuthenticatedUserDoesNotHavePermission) {
        return "You do not have permission to add this user";
    }

    if ((bitwise & CreateProjectTeamAssignmentDomainValidationFailureReasonEnum.ContactEmailDomainDoesNotMatchProjectDomain) === 
            CreateProjectTeamAssignmentDomainValidationFailureReasonEnum.ContactEmailDomainDoesNotMatchProjectDomain) {
        return "Invalid email domain, does not match project domain";
    }

    if ((bitwise & CreateProjectTeamAssignmentDomainValidationFailureReasonEnum.InFlightProjectAdminAssignments) === 
            CreateProjectTeamAssignmentDomainValidationFailureReasonEnum.InFlightProjectAdminAssignments) {
        return "Admin assignment already in progress"; 
    }

    if ((bitwise & CreateProjectTeamAssignmentDomainValidationFailureReasonEnum.DomainNotFound) === 
            CreateProjectTeamAssignmentDomainValidationFailureReasonEnum.DomainNotFound) {
        return "Domain not found";
    }

    if ((bitwise & CreateProjectTeamAssignmentDomainValidationFailureReasonEnum.InvalidDeviceType) === 
            CreateProjectTeamAssignmentDomainValidationFailureReasonEnum.InvalidDeviceType) {
        return "Invalid Device Type";
    }    

    if ((bitwise & CreateProjectTeamAssignmentDomainValidationFailureReasonEnum.ContactEmailAddressInUse) === 
            CreateProjectTeamAssignmentDomainValidationFailureReasonEnum.ContactEmailAddressInUse) {
        return "Email is already in use";
    }

    if ((bitwise & CreateProjectTeamAssignmentDomainValidationFailureReasonEnum.UserPrincipalNameInUse) === 
            CreateProjectTeamAssignmentDomainValidationFailureReasonEnum.UserPrincipalNameInUse) {
        return "User already exists";
    }

    if ((bitwise & CreateProjectTeamAssignmentDomainValidationFailureReasonEnum.TeamNotFound) === 
            CreateProjectTeamAssignmentDomainValidationFailureReasonEnum.TeamNotFound) {
        return "Team not found";
    }    

    return "Unknown error occurred";
};

export {
    projectTeamAssignmentBlockerReason
};
