import React, { useCallback } from "react";
import dayjs from "dayjs";
import { Text } from "@breakingwave/react-ui-components";
import { AccordionToggleIconTypeEnum, IGenericRequestAccordionProps } from "../../types/accordions";
import ProfileProgressCircle from "../profile-progress-circle";
import * as S from "./Accordion.styles";

const GenericRequestAccordion: React.FC<IGenericRequestAccordionProps> = ({
    children,
    profileProgressCircleProps,
    displayName,
    requestId,
    mondayboardId,
    applicant,
    inferredGenericRequestStatus,
    dateCreatedUtc,
    defaultOpenState,
    isToggleable,
    hasToggleIcon,
    enableLinkToMondayboard
}) => {

    const [isOpen, setIsOpen] = React.useState<boolean>(defaultOpenState);

    const onToggleClick = (): void => {
        if (isToggleable) {
            setIsOpen(!isOpen);
        }
    };    

    const handleLinkClick = useCallback((e: React.MouseEvent<HTMLSpanElement>) => {
        e.stopPropagation();
        const url = `${process.env.REACT_APP_MONDAY_BASE_URL}/boards/${mondayboardId}`;
        const mondayBoardWindow = window.open(url, "_blank");
        if (mondayBoardWindow) {
            mondayBoardWindow.focus();
        }
    }, []);

    React.useEffect(() => {
        if (isToggleable && defaultOpenState !== undefined) {
            setIsOpen(defaultOpenState);
        }
    }, [isToggleable, defaultOpenState]);    

    return (
        <S.AccordionWrapper>
            <S.GenericRequestAccordionHeader isToggleable={isToggleable} onClick={onToggleClick} isOpen={isOpen}>
                <ProfileProgressCircle {...profileProgressCircleProps} />
                <Text variant="span" size={14} color="white.50">
                    {displayName}
                </Text>
                <Text variant="span" size={14} color="white.50">
                    {enableLinkToMondayboard ? 
                        <S.AccordionClickableLink onClick={handleLinkClick}>
                            {requestId}
                        </S.AccordionClickableLink> 
                        : 
                        <span>{requestId}</span>
                    }
                </Text>
                <Text variant="span" size={14} color="white.50">
                    {applicant}
                </Text>
                <Text variant="span" size={14} color="white.50">
                    {dayjs(dateCreatedUtc).format("DD MMM YYYY")}
                </Text>
                <Text variant="span" size={14} color="white.50">
                    {inferredGenericRequestStatus}                    
                </Text>                
                {hasToggleIcon &&
                    <S.ToggleIcon toggleIconType={AccordionToggleIconTypeEnum.Chevron} />
                }
            </S.GenericRequestAccordionHeader>
            {isOpen && children}
        </S.AccordionWrapper>
    );
};

export default GenericRequestAccordion;
