import React, { useEffect, useMemo, useRef } from "react";
import { FormControl, Select } from "@breakingwave/react-ui-components";
import { IOption } from "../../../types/selectOption";
import Spinner from "../../../components/spinner";
import useGetGenericTypes from "../../../api/getGenericRequestTypes";
import { GenericRequestPageActionTypesEnum, useRequestPageContext } from "./_state/context";

const GenericRequestSelect: React.FC = () => {

    const selectRef = useRef<any>(null);

    const {
        state: {
            currentGenericRequestType
        },
        dispatch
    } = useRequestPageContext();

    const {
        data: genericRequestTypes,
        isLoading: isLoadingGenericRequestTypes
    } = useGetGenericTypes({
        cacheTime: 0,
        refetchInterval: 0
    });

    const options = useMemo(() => {
        const options: IOption[] = [];
        if (genericRequestTypes) {
            genericRequestTypes.forEach((genericRequestType) => {
                options.push({ 
                    label: genericRequestType.DisplayName,
                    value: genericRequestType.RequestType
                });   
            });
        }
        return options;
    }, [genericRequestTypes]);

    useEffect(() => {
        if (genericRequestTypes && selectRef && currentGenericRequestType === null) {
            selectRef.current.clearValue();
        }
    }, [currentGenericRequestType, genericRequestTypes]);

    const initialiseNewRequest = (requestType: string): void => {
        const genericRequestTypeItem = genericRequestTypes?.find((rt) => rt.RequestType === requestType);
        if (genericRequestTypeItem) {
            dispatch({
                type: GenericRequestPageActionTypesEnum.SET_CURRENT_GENERIC_REQUEST_TYPE,
                payload: genericRequestTypeItem
            });
        }
    };

    return ( 
        <FormControl as="fieldset" style={{ width: 208, marginLeft: "auto" }}>
            {isLoadingGenericRequestTypes ? <Spinner size={1.5} /> : 
                <Select
                    ref={selectRef}
                    name="setSubscription"
                    isDisabled={currentGenericRequestType !== null}
                    variant="button"
                    options={options}
                    placeholder="New request"                
                    onValueChange={(option: IOption) => {
                        if (option !== null) {
                            initialiseNewRequest(option.value);
                        }
                    }}
                />}
        </FormControl>     
    );
};

export default GenericRequestSelect;
