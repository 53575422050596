import { ITaskItem } from "./shared";

export interface IGenericRequestType {
    DisplayName: string;
    RequestType: string;
    DisplayMessage: string;
}

export interface ICreateGenericRequest {
    RequestType: string;
}

export enum GenericRequestTargetsEnum {
    Applicant = "Applicant", // Requests that were made by user with given UPN
    Assignee = "Assignee" // Requests that are assigned to the user with given UPN
}

export interface IGenericRequest {
    ProjectSlug: string;
    id: string;
    DateCreatedUtc: string;
    BoardId: string;
    MondayBoardId: string;
    ProjectName: string;
    FirstName: string;
    LastName: string;
    ContactEmailAddress: string;
    Status: string;
    StatusDateTimeUtc: string;
    TaskItems: ITaskItem[];
    CreatedBy: string;
    CreatedByName: string;
    WaveVersionNumber: string;
    RequestType: string;
    DisplayName: string;
    DisplayMessage: string;
    RequestedByUpn: string;
    RequestedByName: string;
    RequestedByContactEmail: string;
    AssigneeUpn: string;
    AssigneeFirstName: string;
    AssigneeLastName: string;
    AssigneeContactEmail: string;
}

export enum CreateGenericRequestDomainValidationFailureReasonEnum {
    Success = 0,
    ProjectIsNotHomeProject = 1 << 0, // 1
    RequestTypeNotConfigured = 1 << 1, // 2
    ApplicantCannotBeAssignee = 1 << 2, // 4
}

export interface IGenericRequestErrorResponse {
    ValidationResult: CreateGenericRequestDomainValidationFailureReasonEnum;
}
