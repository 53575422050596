import { useMsal } from "@azure/msal-react";
import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { protectedResources } from "../../../auth/authConfig";
import Spinner from "../../../components/spinner";
import { IMFAState } from "../../../types/shared";
import * as S from "./Callback.styles";

const AccountSetupLogin: React.FC = () => {
    const [searchParams] = useSearchParams();
    const { instance } = useMsal();

    useEffect(() => {
        const { id, p, t } = Object.fromEntries([...searchParams]);   

        if (id && p && t) {
            const state: IMFAState = {
                Id: id,
                ProjectId: p,
                AccessToken: t
            };

            instance.handleRedirectPromise().then(() => {
                console.log(`loginRedirect with state: ${JSON.stringify(state)}`);
                instance.loginRedirect({
                    ...protectedResources.graphMe,
                    state: JSON.stringify(state)
                }).catch((e) => {
                    console.log(e);
                });
            });
        }
    }, []);

    return (
        <S.LoadingContainer>
            <Spinner size={3} padding={"0px"} />
            <h1>Please wait...</h1>
        </S.LoadingContainer>
    );
};

export default AccountSetupLogin;
