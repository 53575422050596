import axios from "axios";
import { protectedResources } from "../auth/authConfig";
import { IProjectAdminAssignment, IProjectAdminAssignmentErrorResponse } from "../types/projectAdminAssignment";

const validateProjectAdminAssignment = async (
    projectAdminAssignment: IProjectAdminAssignment,
    projectSlug: string, 
    accessToken: string): Promise<IProjectAdminAssignmentErrorResponse> => {        
    const url = `${protectedResources.wavecore.endpoint}/api/ValidateProjectAdminAssignment/${projectSlug}`;
    const response = await axios({
        method: "POST",
        url: url,
        data: JSON.stringify(projectAdminAssignment),
        headers: {
            "Cache-Control": "no-cache",
            Pragma: "no-cache",
            Expires: "0",
            authorization: `Bearer ${accessToken}`
        }
    });

    return response.data;
};

export default validateProjectAdminAssignment;
