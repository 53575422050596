import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";
import axios from "axios";
import useAadAccessToken from "../hooks/useAadAccessToken";
import { protectedResources } from "../auth/authConfig";
import { QueryKeysEnum } from "../types/queryKeys";
import { IGenericRequestType } from "../types/genericRequests";

const getGenericRequestTypes = async (accessToken: string): Promise<IGenericRequestType[]> => {
    const url = `${protectedResources.wavecore.endpoint}/api/GenericRequestTypes`;
    const response = await axios({
        method: "get",
        url,
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`
        }
    });

    return response.data;
};

const useGetGenericTypes = (
    options?: UseQueryOptions<IGenericRequestType[], unknown, IGenericRequestType[], QueryKeysEnum[]>
): UseQueryResult<IGenericRequestType[], unknown> => {
    const { getAccessToken, isAuthenticated } = useAadAccessToken(protectedResources.wavecore.scopes);

    return useQuery(
        [QueryKeysEnum.genericRequestTypes],
        () => 
            getAccessToken().then((accessToken) => {
                if (accessToken && isAuthenticated) {
                    return getGenericRequestTypes(accessToken);
                }
                return Promise.reject();
            }),
        {
            ...options
        }
    ); 
};

export default useGetGenericTypes;
