import React from "react";
import { useProjectAdminAssignmentStepsContext } from "../_state/context";
import { 
    ProjectAdminAssignmentWizardStepIdentifierEnum, 
    IProjectAdminAssignmentWizardStepsState } from "../_state/types";
import { useProjectPageContext } from "../../../../_state/context";
import { ProjectAdminAssignmentWizardStepsActionTypesEnum } from "../_state/actions";
import ProjectAdminName from "./ProjectAdminName";
import Confirmation from "./Confirmation";
import * as S from "../../wizard.styles";

const ProjectAdminAssignmentWizardSteps: React.FC = () => {

    const { 
        state: {
            projectAdminAssignmentWizard: {
                tempProjectAdminAssignmentStepsData: pageLevelStepsData
            }
        }
    } = useProjectPageContext();

    const { 
        state,
        dispatch 
    } = useProjectAdminAssignmentStepsContext();


    const [activeStep, setActiveStep] = React.useState(Object.values(state).find((f) => f.order === 1).identifier);

    const projectAdminNameStep = state[ProjectAdminAssignmentWizardStepIdentifierEnum.ProjectAdminName];

    React.useEffect(() => {
        const activeStepFromTeamAssignmentWizard = Object.keys(state).find(
            (f) => state[f as keyof IProjectAdminAssignmentWizardStepsState].isStepActive
        );
        setActiveStep(activeStepFromTeamAssignmentWizard);
    }, [state]);

    React.useEffect(() => {
        if (state && 
            pageLevelStepsData && 
            pageLevelStepsData.UserPrincipalName && 
            pageLevelStepsData.FirstName && 
            pageLevelStepsData.LastName && 
            pageLevelStepsData.ContactEmailAddress) {
            dispatch({
                type: ProjectAdminAssignmentWizardStepsActionTypesEnum.SET_PROJECT_ADMIN_NAME,
                payload: {
                    ...projectAdminNameStep,
                    elevateMode: true,
                    userPrincipalName: pageLevelStepsData.UserPrincipalName,
                    answer: {
                        firstName: pageLevelStepsData.FirstName,
                        lastName: pageLevelStepsData.LastName,
                        contactEmailAddress: pageLevelStepsData.ContactEmailAddress,
                        displayValue: `${pageLevelStepsData.FirstName} ${pageLevelStepsData.LastName}`
                    }
                }
            });

            dispatch({
                type: ProjectAdminAssignmentWizardStepsActionTypesEnum.LOAD_NEXT_STEP
            });
        }
    }, [pageLevelStepsData]);  

    return (
        <S.Form autoComplete="off">
            {activeStep === ProjectAdminAssignmentWizardStepIdentifierEnum.ProjectAdminName && <ProjectAdminName />}
            {activeStep === ProjectAdminAssignmentWizardStepIdentifierEnum.Confirmation && <Confirmation />}
        </S.Form>
    );
};

export default ProjectAdminAssignmentWizardSteps;
