import styled from "styled-components";

const RequestsHeader = styled.div<{ marginTop?: string | undefined }>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: ${({ marginTop }) => marginTop ?? "0px"};
`;

const TabHeaderRequests = styled.div`
    margin-left: 14px;
    display: grid;
    grid-template-columns: 280px 250px 280px 280px 150px;
    margin-top: 4em;
    margin-bottom: 12px;
`;

const GenericRequestWrapper = styled.div`
    height: 234px;
    border: 1px solid #595959;
    background-color: #242424;
    border-radius: 4px;
    margin-bottom: 8px;
    display: flex;
    flex-direction: column;
    padding-left: 85px;
    padding-top: 25px;
    padding-bottom: 20px;    
`;

const OptionsWrapper = styled.div`
    display: flex;
    margin-top: auto;

    button {
        margin-right: 12px;
    }
`;

const Line = styled.div`
    margin-bottom: 3.2em;
    margin-top: 4.2em;
    border-bottom: 1px solid #707070;
`;

export {
    RequestsHeader,
    TabHeaderRequests,
    GenericRequestWrapper,
    OptionsWrapper,
    Line
};
