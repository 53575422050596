import React from "react";
import { ProjectAdminAssignmentWizardStepsActionTypesEnum } from "./actions";
import { IProjectAdminAssignmentWizardStepsState, ProjectAdminAssignmentWizardStepIdentifierEnum } from "./types";
import initialProjectAdminAssignmentStepsState from "./initialState";

export interface ILoadNextStepAction {
    type: ProjectAdminAssignmentWizardStepsActionTypesEnum.LOAD_NEXT_STEP;
}

export interface ILoadPrevStepAction {
    type: ProjectAdminAssignmentWizardStepsActionTypesEnum.LOAD_PREV_STEP;
}

export interface ISetProjectAdminNameAction {
    type: ProjectAdminAssignmentWizardStepsActionTypesEnum.SET_PROJECT_ADMIN_NAME;
    payload: IProjectAdminAssignmentWizardStepsState[ProjectAdminAssignmentWizardStepIdentifierEnum.ProjectAdminName];
}

export interface ISetConfirmationAction {
    type: ProjectAdminAssignmentWizardStepsActionTypesEnum.SET_CONFIRMATION;
    payload: IProjectAdminAssignmentWizardStepsState[ProjectAdminAssignmentWizardStepIdentifierEnum.Confirmation];
}

export type ProjectAdminWizardStepsActions = 
    | ILoadNextStepAction
    | ILoadPrevStepAction
    | ISetProjectAdminNameAction
    | ISetConfirmationAction;

export const reducer: React.Reducer<IProjectAdminAssignmentWizardStepsState, ProjectAdminWizardStepsActions> = (state, action) => {
    const activeStep = Object.keys(state)
        .filter((f) => state[f as keyof IProjectAdminAssignmentWizardStepsState].isStepActive)
        .reduce((r) => r.toString());
    const activeStepOrder = state[activeStep as keyof IProjectAdminAssignmentWizardStepsState].order;

    switch (action.type) {
        case ProjectAdminAssignmentWizardStepsActionTypesEnum.LOAD_NEXT_STEP: {
            if (activeStepOrder >= 1 && activeStepOrder < Object.keys(state).length) {
                const nextStepFromActiveStepOrder = Object.keys(state).find(
                    (f) => state[f as keyof IProjectAdminAssignmentWizardStepsState].order === activeStepOrder + 1
                );
                return {
                    ...state,
                    [activeStep as keyof IProjectAdminAssignmentWizardStepsState]: {
                        ...state[activeStep as keyof IProjectAdminAssignmentWizardStepsState],
                        isStepActive: false,
                        isStepAnswered: true
                    },
                    [nextStepFromActiveStepOrder as keyof IProjectAdminAssignmentWizardStepsState]: {
                        ...state[nextStepFromActiveStepOrder as keyof IProjectAdminAssignmentWizardStepsState],
                        isStepActive: true
                    }
                };
            } else {
                // Error: Next step not found.
                return state;
            }
        }
        case ProjectAdminAssignmentWizardStepsActionTypesEnum.LOAD_PREV_STEP: {
            const prevStepFromActiveStepOrder = Object.keys(state).find(
                (f) => state[f as keyof IProjectAdminAssignmentWizardStepsState].order === activeStepOrder - 1
            );
            if (activeStepOrder > 1 && activeStepOrder <= Object.keys(state).length) {
                return {
                    ...state,
                    [activeStep as keyof IProjectAdminAssignmentWizardStepsState]: {
                        ...state[activeStep as keyof IProjectAdminAssignmentWizardStepsState],
                        isStepActive: false
                    },
                    [prevStepFromActiveStepOrder as keyof IProjectAdminAssignmentWizardStepsState]: {
                        ...state[prevStepFromActiveStepOrder as keyof IProjectAdminAssignmentWizardStepsState],
                        isStepActive: true,
                        isStepAnswered: false
                    }
                };
            } else {
                // Error: Previous step not found.
                return state;
            }
        }
        case ProjectAdminAssignmentWizardStepsActionTypesEnum.SET_PROJECT_ADMIN_NAME: {
            return {
                ...state,
                [ProjectAdminAssignmentWizardStepIdentifierEnum.ProjectAdminName]: {
                    ...action.payload
                }
            };
        }
        case ProjectAdminAssignmentWizardStepsActionTypesEnum.SET_CONFIRMATION: {
            return {
                ...state,
                [ProjectAdminAssignmentWizardStepIdentifierEnum.Confirmation]: {
                    ...action.payload
                }
            };
        }
        default:
            return state;
    }
};

interface IProjectAdminAssignmentStepsContextProps {
    state: IProjectAdminAssignmentWizardStepsState;
    dispatch: React.Dispatch<ProjectAdminWizardStepsActions>;
}

export const ProjectAdminAssignmentStepsContext = React.createContext<IProjectAdminAssignmentStepsContextProps>({
    state: initialProjectAdminAssignmentStepsState,
    dispatch: () => null
});

export const useProjectAdminAssignmentStepsContext = (): IProjectAdminAssignmentStepsContextProps => 
    React.useContext(ProjectAdminAssignmentStepsContext);
