import React from "react";
import { IGenericRequestDisplayItemsResponse } from "../../../../types/genericRequestDisplayItems";
import GenericRequestAccordion from "../../../../components/accordion/GenericRequestAccordion";
import { genericRequestStatusColours } from "../index.config";
import { UserTypeEnum } from "../../../../types/shared";

interface IGenericRequestRowProps {
    genericRequest: IGenericRequestDisplayItemsResponse;
}

const GenericRequestRow: React.FC<IGenericRequestRowProps> = ({
    genericRequest
}) => <>
    <GenericRequestAccordion 
        dateCreatedUtc={genericRequest.DateCreatedUtc}
        displayName={genericRequest.DisplayName}
        requestId={genericRequest.RequestId}
        applicant={genericRequest.Applicant}
        inferredGenericRequestStatus={genericRequest.InferredGenericRequestStatus}
        profileProgressCircleProps={{
            size: 30,
            percentage: 100,
            userType: UserTypeEnum.User,
            percentagePathColour: genericRequestStatusColours.memberIcon[genericRequest.InferredGenericRequestStatus]
        }}
        defaultOpenState={false}
        isToggleable={false}
        hasToggleIcon={false}
        mondayboardId={genericRequest.MondayBoardId}
        enableLinkToMondayboard={false}
    >

    </GenericRequestAccordion>
</>;

export default GenericRequestRow;
