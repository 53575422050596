import { InferredGenericRequestStatusEnum } from "../../../types/genericRequestDisplayItems";
import { TaskGroupStatusesEnum } from "../../../types/shared";

interface IGenericRequestStatusColours {
    memberIcon: {
        [InferredGenericRequestStatusEnum.Completed]: string;
        [InferredGenericRequestStatusEnum.Failed]: string;
        [InferredGenericRequestStatusEnum.Indeterminate]: string;
        [InferredGenericRequestStatusEnum.Processing]: string;
        [InferredGenericRequestStatusEnum.Rejected]: string;
    };
    status: {
        [TaskGroupStatusesEnum.Pending]: string;
        [TaskGroupStatusesEnum.InProgress]: string;
        [TaskGroupStatusesEnum.Complete]: string;
        [TaskGroupStatusesEnum.Failed]: string;
    };
}

export const genericRequestStatusColours: IGenericRequestStatusColours = {
    memberIcon: {
        Completed: "#00ff9d",
        Failed: "#ff406e",
        Indeterminate: "#cacaca",
        Processing: "#00ff9d",
        Rejected: "#aa29ff"
    },
    status: {
        Pending: "#bebebe",
        InProgress: "#00ff9d",
        Complete: "#00ff9d",
        Failed: "#ff406e"
    }
};
