import { IProjectAdminAssignmentWizardStepsState, ProjectAdminAssignmentWizardStepIdentifierEnum } from "./types";

const initialProjectAdminAssignmentStepsState: IProjectAdminAssignmentWizardStepsState = {
    [ProjectAdminAssignmentWizardStepIdentifierEnum.ProjectAdminName]: {
        order: 1,
        isStepAnswered: false,
        isStepActive: true,
        elevateMode: false,
        userPrincipalName: undefined,
        answer: {
            firstName: "",
            lastName: "",
            contactEmailAddress: "",
            displayValue: "-"
        }
    }, 
    [ProjectAdminAssignmentWizardStepIdentifierEnum.Confirmation]: {
        order: 2,
        isStepAnswered: false,
        isStepActive: false,
        answer: {
            confirmation: false
        }
    }     
};

export default initialProjectAdminAssignmentStepsState;
