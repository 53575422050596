import { ITaskGroupStatusResponse } from "./groupStatuses";

export enum InferredGenericRequestStatusEnum {
    Indeterminate = "Indeterminate",
    Processing = "Processing",
    Completed = "Completed",
    Failed = "Failed",
    Rejected = "Rejected"
}

export enum GenericRequestStateEnum {
    Active = "Active",
    Archived = "Archived"
}

export interface IGenericRequestDisplayItemsResponse {
    DisplayName: string;
    RequestType: string;
    RequestId: number;
    MondayBoardId: string;
    DateCreatedUtc: Date;
    Applicant: string;
    InferredGenericRequestStatus: InferredGenericRequestStatusEnum;
    State: GenericRequestStateEnum;
    GroupStatuses: ITaskGroupStatusResponse[];
}
