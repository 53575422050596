/* eslint-disable @typescript-eslint/naming-convention */
import React from "react";
import { IGenericRequest, IGenericRequestType } from "../../../../types/genericRequests";

export enum GenericRequestPageActionTypesEnum {
    SET_CURRENT_GENERIC_REQUEST_TYPE = "SET_CURRENT_GENERIC_REQUEST_TYPE",
    SET_SAVED_GENERIC_REQUEST = "SET_SAVED_GENERIC_REQUEST",
}

export interface IGenericRequestPageState {
    currentGenericRequestType: IGenericRequestType | null;
    savedGenericRequest: IGenericRequest | null;
}

export const initialGenericRequestPageState: IGenericRequestPageState = {
    currentGenericRequestType: null,
    savedGenericRequest: null
};

export interface ISetCurrentGenericRequestType {
    type: GenericRequestPageActionTypesEnum.SET_CURRENT_GENERIC_REQUEST_TYPE;
    payload: IGenericRequestType | null;
}

export interface ISetSavedGenericRequest {
    type: GenericRequestPageActionTypesEnum.SET_SAVED_GENERIC_REQUEST;
    payload: IGenericRequest | null;
}

export type GenericRequestPageActions = 
    ISetCurrentGenericRequestType | 
    ISetSavedGenericRequest;

export const reducer: React.Reducer<IGenericRequestPageState, GenericRequestPageActions> = (state, action) => {
    switch (action.type) {

        case GenericRequestPageActionTypesEnum.SET_CURRENT_GENERIC_REQUEST_TYPE: {
            return {
                ...state,
                currentGenericRequestType: action.payload
            };
        }

        case GenericRequestPageActionTypesEnum.SET_SAVED_GENERIC_REQUEST: {
            return {
                ...state,
                savedGenericRequest: action.payload
            };       
        }

        default: 
            return state;
    }
};

export interface IGenericRequestPageContextProps {
    state: IGenericRequestPageState;
    dispatch: React.Dispatch<GenericRequestPageActions>;
}

export const GenericRequestPageContext = React.createContext<IGenericRequestPageContextProps>({
    state: initialGenericRequestPageState,
    dispatch: () => null
});

export const useRequestPageContext = (): IGenericRequestPageContextProps => React.useContext(GenericRequestPageContext);

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const GenericRequestPageProvider = ({ children }: any) => {
    const [state, dispatch] = React.useReducer(reducer, initialGenericRequestPageState);
    const value = { state, dispatch };
    return <GenericRequestPageContext.Provider value={value}>{children}</GenericRequestPageContext.Provider>;
};
